/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    blockquote: "blockquote",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://litox9.files.wordpress.com/2012/06/manifiesto_agil.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHq7FFE1f/EABoQAQACAwEAAAAAAAAAAAAAAAEAERASITH/2gAIAQEAAQUCEIt4rupDz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAABAgIf/aAAgBAQAGPwJKP//EABkQAAMBAQEAAAAAAAAAAAAAAAABESExof/aAAgBAQABPyFgs8KsJCXWnajCJM//2gAMAwEAAgADAAAAEBwf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8QNHp30IyAKCjIvNX8m10ldlAFXarIlhoPM//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"El Manifiesto Ágil\"\n        title=\"Manifiesto_agil\"\n        src=\"/static/017db7f5b9d9a6f516dbaaf3c17cc9f8/f93b5/manifiesto_agil.jpg\"\n        srcset=\"/static/017db7f5b9d9a6f516dbaaf3c17cc9f8/ff44c/manifiesto_agil.jpg 158w,\n/static/017db7f5b9d9a6f516dbaaf3c17cc9f8/f93b5/manifiesto_agil.jpg 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Nos dice mucho del mundo en que vivimos las diferentes técnicas que se usan para hacer las cosas, la aplicación de la misma técnica en dos ámbitos muy distintos nos da una idea de hacia donde se dirigen las filosofías imperantes. Por eso me llama la atención como funciona el mantenimiento proactivo, una forma de mantenimiento que consiste en un proceso de mejora continua del producto, buscando entender el fallo para poder eliminar su causa. Ésto concuerda con la idea del producto que tiene el desarrollo ágil, ampliamente utilizado en el desarrollo de software."), "\n", React.createElement(_components.p, null, "En desarrollo ágil no se trata al producto como algo definitivo, si no como un producto mejorable permanentemente, el proyectos ágiles no funcionan a la antigua manera industrial, donde un producto debía durar lo más posible tal y como fue diseñado, en la era industrial los costes de diseño eran altos por que el resultado tenía que ser robusto. En la era informacional el cambio es algo del día a día y los productos robustos y caros dejan de dar los beneficios que tenían antes, por lo tanto se opta a por seguir una filosofía de desarrollo a bajo coste y mejora continua, podemos ver esta tendencia en la publicación del ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Manifiesto_%C3%A1gil",
    title: "Manifiesto Ágil"
  }, "manifiesto ágil"), ", cuyo cuarto punto dice así:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La ", React.createElement(_components.strong, null, "respuesta al cambio"), ", por encima del seguimiento de un plan."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "En la era informacional el plan pasa a un segundo plano y se toman más en cuenta los cambios. En la era informacional, como hemos visto, hasta la industria está cambiando, el mantenimiento proactivo sólo es un ejemplo; también está la ", React.createElement(_components.a, {
    href: "http://david.lasindias.com/la-revolucion-p2p-contada-en-10-minutos/",
    title: "La revolución P2P"
  }, "producción P2P"), ", un ejemplo de lo que se nos acerca."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
